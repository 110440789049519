/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6744c526-a3d5-49d4-9e82-0b1b5468bd91",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_357iEC6i8",
    "aws_user_pools_web_client_id": "1mmvp6itq6u139b84lskcblb6e",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://hhll3o73vvb7hcab4zczfwrifu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lxw5dwg7pfheznmamo5adzxayq",
    "aws_user_files_s3_bucket": "october-picture-bucket132733-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
